import create from 'zustand';
import { addEffect } from '@react-three/fiber';

async function createAudio(url, { threshold, expire } = {}) {
  const res = await fetch(url);
  const buffer = await res.arrayBuffer();
  const context = new (window.AudioContext || window.webkitAudioContext)();
  const analyser = context.createAnalyser();
  analyser.fftSize = 2048;
  const data = new Uint8Array(analyser.frequencyBinCount);
  const source = context.createBufferSource();
  source.buffer = await new Promise(res =>
    context.decodeAudioData(buffer, res)
  );
  source.loop = true;
  const gainNode = context.createGain();
  gainNode.gain.value = 1;
  gainNode.connect(context.destination);
  source.connect(analyser);
  analyser.connect(gainNode);

  let time = Date.now();
  let state = {
    source,
    data,
    gain: 1,
    signal: false,
    avg: 0,
    context,
    update: () => {
      let now = Date.now();
      let value = 0;
      analyser.getByteFrequencyData(data);
      for (let i = 0; i < data.length; i++) value += data[i];
      const avg = (state.avg = value / data.length);
      if (threshold && avg > threshold && now - time > expire) {
        time = Date.now();
        state.signal = true;
      } else state.signal = false;
    },
    setGain(level) {
      gainNode.gain.setValueAtTime((state.gain = level), context.currentTime);
    }
  };

  return state;
}

const mockData = () => ({ signal: false, avg: 0, gain: 1, data: [] });

const useStore = create((set, get) => {
  const melodic = createAudio('/audio/melodic.mp3');
  const dub = createAudio('/audio/dub.mp3');
  const background = createAudio('/audio/background.mp3');
  return {
    loaded: false,
    clicked: false,
    audios: [mockData(), mockData(), mockData()],
    track: { synthonly: false, kicks: 0, loops: 0 },

    camPositions: [
      [-1, 1, 1],
      [-0.5, 0.5, -4],
      [1, 1, 2]
    ],

    camTargets: [
      [0, 0, 0],
      [0, 1, 0],
      [1, 1, 0]
    ],

    index: 0,
    setIndex: index => set({ index: index }),

    api: {
      async loaded() {
        set({
          loaded: true,
          audios: [await melodic, await dub, await background]
        });
      },
      start(index) {
        const audios = get().audios;
        const files = Object.values(audios);
        const track = get().track;

        audios.forEach(audio => {
          // if (!audio.context.state === 'suspended') {

          console.log(audio);

          try {
            audio.source.start(0);
          } catch (err) {
            console.log('errrrr', audio);
          }

          // }

          audio.context.suspend();
        });

        audios[index].context.resume();

        // console.log(audios[index].source);

        // files.forEach(({ source }) => source.start(0));
        set({ clicked: true });
        addEffect(() => {
          audios[index].update();

          // files.forEach(({ update }) => update());
          // if (audio.melodic.signal) track.kicks++;
          // if (audio.dub.signal) {
          //   if (track.loops++ > 6) {
          //     track.synthonly = !track.synthonly;
          //     audio.melodic.setGain(track.synthonly ? 0 : 1);
          //     audio.dub.setGain(track.synthonly ? 0 : 1);
          //     track.loops = 0;
          //   }
          //   track.kicks = 0;
          // }
        });
      }
    }
  };
});

export default useStore;
