import * as THREE from 'three';
import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import {
  useGLTF,
  useAnimations,
  Reflector,
  MeshReflectorMaterial,
  useTexture,
  OrbitControls,
  CameraShake
} from '@react-three/drei';
import useStore from './store';
import { useControls, folder } from 'leva';

const HPI = Math.PI / 2;
const vec = new THREE.Vector3();
const obj = new THREE.Object3D();
const red = new THREE.Color('#ff0000');

export default function App(props) {
  const index = useStore(state => state.index);
  const audios = useStore(state => state.audios);

  console.log('index', index);

  const controlsRef = useRef();

  return (
    <>
      <Canvas dpr={[1, 1]} camera={{ position: [-2, 2, 4.5] }} shadows>
        <color attach='background' args={['#d0d0d0']} />
        <fog attach='fog' args={['#d0d0d0', 1, 7.5]} />

        <OrbitControls ref={controlsRef} makeDefault />

        <directionalLight
          castShadow
          position={[0, 10, -5]}
          shadow-mapSize={[2048, 2048]}
          intensity={5}
        />

        <Suspense fallback={null}>
          <group position-y={-0.25}>
            {audios.map((_audio, i) => (
              <Graph
                key={i}
                position={[-0.7, -0.2 + i * 0.5, -i - 1]}
                audio={i}
              />
            ))}

            <mesh
              receiveShadow
              position={[0, -0.3, 0]}
              rotation={[-Math.PI / 2, 0, 0]}
              scale={10}
            >
              <planeBufferGeometry />
              <shadowMaterial />
            </mesh>
          </group>

          <CamPos />
        </Suspense>
      </Canvas>
      <Buttons />
    </>
  );
}

function Graph(props) {
  const audios = useStore(state => state.audios);

  const ref = useRef();
  useFrame(() => {
    for (let i = 0; i < 64; i++) {
      obj.position.set(i * 0.04, audios[props.audio].data[i] / 1000, 0);
      obj.updateMatrix();
      ref.current.setMatrixAt(i, obj.matrix);
    }
    ref.current.instanceMatrix.needsUpdate = true;
  });
  return (
    <instancedMesh ref={ref} args={[null, null, 64]} {...props} castShadow>
      <planeGeometry args={[0.02, 0.1]} />
      <meshBasicMaterial
        color={red}
        side={2}
        // toneMapped={false}
        // transparent
        // opacity={0.25}
      />
    </instancedMesh>
  );
}

function Buttons() {
  const setIndex = useStore(state => state.setIndex);
  const api = useStore(state => state.api);

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        textAlign: 'center',
        pointerEvents: 'none'
      }}
    >
      {[0, 1, 2].map(index => (
        <button
          style={{ width: 50, height: 50, pointerEvents: 'all' }}
          key={index}
          onClick={() => {
            setIndex(index);

            api.start(index);
          }}
        >
          {index}
        </button>
      ))}
    </div>
  );
}

const cameraTarget = new THREE.Vector3(0, 0, 0);
const dummy = new THREE.Vector3(0, 0, 0);

function CamPos() {
  const clicked = useStore(state => state.clicked);
  const api = useStore(state => state.api);
  useEffect(() => {
    api.loaded();
  }, []);

  const index = useStore(state => state.index);

  const camPosition = useStore(state => state.camPositions)[index];
  const camTarget = useStore(state => state.camTargets)[index];

  // Zoom in camera when user has pressed start
  return useFrame(state => {
    if (clicked) {
      state.camera.position.lerp(vec.set(...camPosition), 0.05);

      dummy.lerp(cameraTarget.set(...camTarget), 0.025);

      const elapsedTime = state.clock.getElapsedTime();

      const shakeFactor = 0.25;
      const shakeSpeedFactor = 1.2;

      dummy.x +=
        Math.sin(shakeSpeedFactor * elapsedTime * 0.936735) *
        0.008476454 *
        Math.cos(shakeSpeedFactor * elapsedTime * 0.936735) *
        shakeFactor;
      dummy.y +=
        Math.sin(shakeSpeedFactor * elapsedTime * 1.02736) *
        0.00347354 *
        Math.cos(shakeSpeedFactor * elapsedTime * 1.02736) *
        shakeFactor;
      dummy.z +=
        Math.sin(shakeSpeedFactor * elapsedTime * 1.18267) *
        0.00767483 *
        Math.cos(shakeSpeedFactor * elapsedTime * 1.18267) *
        shakeFactor;

      state.camera.lookAt(dummy);
    }
  });
}
